import React, { useState } from 'react';
import { Box, Typography, Button, Collapse } from '@material-ui/core';
import DataScientist from '../../Common/Icons/DataScientist';
import Share from '../../Common/Share';
import AdministrationAutre from '../../Common/Icons/AdministrationAutre';
import AnalyseDeveveloppement from '../../Common/Icons/AnalyseDeveveloppement';
import DesignGraphisme from '../../Common/Icons/DesignGraphisme';
import ToutesCategories from '../../Common/Icons/ToutesCategories';
import { categorie_article as categorie } from './utils';
import styles from './style';
import Form from './Form/Form';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

interface IOffre {
  title: string;
  content: string;
  date: string;
  icon: string;
  index: number;
  type_contrat: string;
  id: number;
  slug: string;
  open: boolean;
}

const Icon = (props: any) => {
  const { icon } = props;
  if (icon === categorie.DATASCIENCE) return <DataScientist />;
  if (icon === categorie.ANALYSE) return <AnalyseDeveveloppement />;
  if (icon === categorie.DESIGN) return <DesignGraphisme />;
  if (icon === categorie.ADMIN) return <AdministrationAutre />;
  else return <ToutesCategories />;
};

function Offre({
  title,
  content,
  date,
  icon,
  index,
  type_contrat,
  id,
  slug,
  open: openProps,
}: IOffre) {
  const classes = styles();
  const [open, setOpen] = useState(openProps);
  const [form, setOpenForm] = useState(false);
  const handleClick = (link: string) => navigate(link);
  const handleClickContent = () => setOpen(!open);
  const handleCloseForm = () => setOpenForm(!form);
  const key = icon.toLocaleLowerCase().replace(/\s/g, '-');
  const goto = (link: string) => {
    navigate(link);
  };
  return (
    <Box className={classes.tabContent} id={`${key}-${index}`}>
      <Collapse collapsedHeight={220} in={open}>
        <Box className={classes.containerOffre}>
          <Box className={classes.icon}>
            <Icon icon={icon} />
          </Box>
          <Box>
            <Box className={classes.title}>
              <Typography variant="h4">
                <span>Poste: </span>
                <span>{title}</span>
              </Typography>
              <Typography
                variant="body2"
                className={classes.date}
              >{`Publié le ${date}`}</Typography>
            </Box>
            <Link className={classes.link} to={`/recrutement/${slug}/${id}`}>
              <Box
                className={classes.content}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Link>

            <Box className={open ? classes.dividerNormale : classes.divider} />
            <Box className={open ? classes.actions : classes.actionsAbsolute}>
              <Box className={classes.contentButton}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 24 }}
                  onClick={handleCloseForm}
                >
                  Déposer votre cv et lettre de motivation
                </Button>
                {!open && (
                  <Button
                    className={classes.outlinedButton}
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleClick(`/recrutement/${slug}/${id}`)}
                  >
                    Voir plus
                  </Button>
                )}
                <Link className={classes.link} to={`/recrutement/${slug}`}>
                  {open && (
                    <Button
                      className={classes.outlinedButton}
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate(-1)}
                    >
                      Fermer
                    </Button>
                  )}
                </Link>
              </Box>
              {/*open ? (
                <Box className={classes.contentShare}>
                  <Share htmlColor="#C4C4C4" />
                </Box>
              ) : (*/}

              <Box className={classes.contrat}>
                <Typography>Type de contrat :</Typography>
                <Typography className={classes.typeContrat}>
                  {type_contrat}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Form open={form} handleClose={handleCloseForm} />
    </Box>
  );
}

export default Offre;
