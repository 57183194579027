import React from 'react';

import { Box, Typography, Divider } from '@material-ui/core';
import styles from './style';
import ArrowUp from '../Icons/ArrowUp';
import { AnchorLink as Link } from 'gatsby-plugin-anchor-links';

interface PropTypes {
  element?: string;
}
const ScrollTo = ({ element }: PropTypes) => {
  const classes = styles();
  return (
    <Box display="flex" className={classes.scrollTop}>
      <Link to={element}>
        <Typography variant="body2" component="span">
          Vers le haut
        </Typography>
        <Divider className={classes.divider} />
        <Box display="flex" className={classes.imgWrapper}>
          <ArrowUp width="24" height="24" viewBox="0 0 24 24" />
        </Box>
      </Link>
    </Box>
  );
};

export default ScrollTo;
