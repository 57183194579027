import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    maxWidth: 600,
    position: 'relative',
    width: '100%',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      marginBottom: 0,
      textTransform: 'initial',
      fontWeight: 'normal',
    },
  },
  modalContent: {
    padding: theme.spacing(5, 3, 3),
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
  },
  buttonClose: {
    color: 'inherit',
    marginLeft: 'auto',
  },
  uploadFile: {
    display: 'flex',
    height: 36,
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.6)',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 4,
    cursor: 'pointer',
    position: 'relative',
    transition: 'border ease .2s',
    '&:hover': {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  },
  sendButton: {
    display: 'inherit',
    marginLeft: 'auto',
    marginTop: theme.spacing(5),
  },
}));
