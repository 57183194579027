import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ArrowUp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="Tracé_11129"
        data-name="Tracé 11129"
        d="M0,24H24V0H0Z"
        fill="none"
      />
      <g
        id="Groupe_4007"
        data-name="Groupe 4007"
        transform="translate(2.757 7.379)"
      >
        <path
          id="Tracé_11256"
          data-name="Tracé 11256"
          d="M-808.962,1823.672l-6.535-6.535-6.536,6.535h-2.707l9.242-9.242,9.242,9.242Z"
          transform="translate(824.739 -1814.43)"
          fill="#ccc"
        />
        <path
          id="Tracé_11257"
          data-name="Tracé 11257"
          d="M-733.9,1818.751l-4.322-4.321-4.321,4.321Z"
          transform="translate(747.459 -1809.509)"
          fill="#ccc"
        />
      </g>
    </SvgIcon>
  );
};

export default ArrowUp;
