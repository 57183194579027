import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        id="Tracé_3602"
        data-name="Tracé 3602"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Tracé_3603"
        data-name="Tracé 3603"
        d="M18.99,11.5a8.258,8.258,0,0,1,1,.07L20,0,0,20H11.56a8.258,8.258,0,0,1-.07-1,7.5,7.5,0,0,1,7.5-7.5Zm3.71,7.99a3.886,3.886,0,0,0,.04-.49,2.628,2.628,0,0,0-.04-.49l1.06-.83a.26.26,0,0,0,.06-.32l-1-1.73a.254.254,0,0,0-.31-.11l-1.24.5a3.421,3.421,0,0,0-.85-.49l-.19-1.32a.239.239,0,0,0-.24-.21h-2a.257.257,0,0,0-.25.21l-.19,1.32a4,4,0,0,0-.85.49l-1.24-.5a.262.262,0,0,0-.31.11l-1,1.73a.248.248,0,0,0,.06.32l1.06.83a4.017,4.017,0,0,0,0,.98l-1.06.83a.26.26,0,0,0-.06.32l1,1.73a.254.254,0,0,0,.31.11l1.24-.5a3.421,3.421,0,0,0,.85.49l.19,1.32a.249.249,0,0,0,.25.21h2a.257.257,0,0,0,.25-.21l.19-1.32a3.7,3.7,0,0,0,.84-.49l1.25.5a.262.262,0,0,0,.31-.11l1-1.73a.26.26,0,0,0-.06-.32ZM18.99,20.5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,18.99,20.5Z"
      />
    </SvgIcon>
  );
}
