import { makeStyles } from '@material-ui/core';
import Bg from '../../../images/rejoindre/element_rejoignez.svg';

export default makeStyles((theme) => ({
  recrutement: {
    padding: theme.spacing(0),
  },
  tabsContainer: {
    display: 'flex',
    '@media (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  containerOffre: {
    display: 'flex',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  BgHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '0',
    backgroundPositionY: '0',
  },
  tabs: {
    flexShrink: 0,
    '& .MuiTab-root': {
      padding: 0,
    },
    '& .MuiTab-labelIcon': {
      minHeight: 45,
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
      textAlign: 'left',
      textTransform: 'initial',
      color: theme.palette.primary.main,
      '& svg': {
        marginRight: theme.spacing(2),
        marginBottom: '0 !important',
      },
      '& .MuiTypography-root': {
        '@media (max-width: 1130px)': {
          fontSize: '0.875rem',
        },
      },
    },
    '& .MuiTab-textColorInherit': {
      opacity: 1,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiTab-wrapper': {
        color: '#FFF',
      },
    },
  },
  tabPanel: {
    flexGrow: 1,
    marginLeft: theme.spacing(9.25),
    '@media (max-width: 1180px)': {
      marginLeft: theme.spacing(3.75),
    },
    '@media (max-width: 650px)': {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(4),
    },
  },
  tabContent: {
    position: 'relative',
    padding: theme.spacing(3),
    border: '1px solid #333333',
    marginBottom: theme.spacing(3),
    '@media (max-width: 442px)': {
      padding: theme.spacing(2),
    },
    '& .MuiCollapse-container': {
      '@media (max-width: 945px)': {
        minHeight: '263px!important',
      },
    },
  },
  icon: {
    marginRight: theme.spacing(3),
    '@media (max-width: 442px)': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    fontWeight: 600,
    '& span + span': {
      color: theme.palette.secondary.main,
    },
  },
  date: {
    fontSize: '0.75rem',
  },
  content: {
    marginBottom: theme.spacing(5),
    '& p, & h2': {
      margin: theme.spacing(0, 0, 1),
      fontWeight: 400,
    },
    '& ul': {
      margin: theme.spacing(0, 0, 2),
      paddingLeft: 0,
      listStyle: 'none',
      '& li::before': {
        content: `'-'`,
        marginRight: theme.spacing(1),
      },
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
    },
  },
  contentButton: {
    '& button': {
      '@media (max-width: 962px)': {
        margin: '8px 0',
      },
    },
  },
  outlinedButton: {
    color: '#7A8692',
    border: '1px solid rgba(122, 134, 146, 0.5)',
    borderColor: '#7A8692',
    borderRadius: 4,
    '&:hover': {
      border: '1px solid rgba(122, 134, 146, 0.5)',
      borderColor: '#7A8692',
      backgroundColor: 'rgba(122, 134, 146, 0.1)',
    },
  },
  actionsAbsolute: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '14px 24px 24px 71px',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1100px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 442px)': {
      padding: theme.spacing(2),
    },
  },
  divider: {},
  dividerNormale: {
    width: '100%',
    height: '1px',
    border: '1px solid #CCCCCC',
    margin: '12px 24px 12px 0',
  },
  contentShare: {
    '& > div': {
      margin: 0,
      '@media (max-width: 1230px)': {
        marginTop: 20,
      },
    },
  },
  containerRecrutement: {
    maxWidth: 1664,
    margin: '0 auto',
    padding: '0 24px 80px',
  },
  contentHeaderRecrutement: {
    padding: '0 24px 40px',
    position: 'relative',
  },
  subContentHeaderRecrutement: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 20,
  },
  cardMedia1: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',

    '@media (max-width: 1280px)': {
      flexBasis: '40%',
    },
    '@media (max-width: 1200px)': {
      flexBasis: '45%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardTitle: {
    paddingLeft: 0,
    '@media (max-width: 992px)': {
      paddingRight: 0,
      width: '100%',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      margin: 0,
      '& span': {},
      '& > .MuiCardHeader-content': {
        // maxWidth: 'fit-content',
      },
    },
    '@media (max-width: 576px)': {},
    '& > .MuiCardHeader-avatar': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 77,
      height: 77,
      border: '1px solid #27272729',
      borderRadius: '50%',
      '@media (min-width: 769px) and (max-width: 991px)': {
        width: 44,
        height: 44,
      },
      '@media (max-width: 576px)': {
        width: 44,
        height: 44,
      },
      '& svg': {
        fontSize: '3rem',
        '@media (min-width: 769px) and (max-width: 991px)': {
          fontSize: '2rem',
        },
        '@media (max-width: 576px)': {
          fontSize: '2rem',
        },
      },
    },
    '& span': {
      lineHeight: 1.2,
      fontWeight: 400,
      textTransform: 'initial',
      fontFamily: 'ubuntu',
      fontSize: '2.125rem',
      '@media (min-width: 1200px) and (max-width: 1600px)': {
        fontSize: '2rem',
      },
      '@media (min-width: 992px) and (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width: 769px) and (max-width: 991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      '& span': {
        fontWeight: 600,
      },
      '& + span': {
        fontWeight: 300,
        fontSize: '1.25rem',
        marginTop: 20,
      },
      '& br': {
        '@media (max-width: 768px)': {
          display: 'none',
        },
      },
    },
  },
  cardTitleTop: {
    '& span': {
      fontSize: '6.5rem',
      fontWeight: 600,
      '@media (max-width: 1599px)': {
        fontSize: '6rem',
      },
      '@media (max-width: 1444px)': {
        fontSize: '5.5rem',
      },
      '@media (max-width: 1200px)': {
        fontSize: '4.5rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 365px)': {
        fontSize: '2.5rem',
      },
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '50%',
    // padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 1200px)': {
      paddingTop: 10,
      paddingLeft: 0,
      paddingBottom: 10,
    },
    '@media (max-width: 992px)': {
      width: '100%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      order: '2 !important',
    },
    '&:hover>div:last-of-type': {
      opacity: 1,
    },
  },
  contrat: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: 12,
      fontFamily: 'ubuntu',
    },
    '@media (max-width: 1100px)': {
      marginTop: theme.spacing(2),
    },
  },
  typeContrat: {
    marginLeft: 8,
    fontWeight: 'bold',
  },
}));
