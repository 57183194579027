import React, { useContext } from 'react';
import Container from '../components/Container';
import Recrutement from '../components/PagesComponent/Recrutement/Recrutement';
import { filterOffer } from '../components/PagesComponent/Recrutement/utils';
import SEO from '../components/seo';

import { RecrutementContext } from '../providers/recrutement';

const RecrutementPage = (props: any) => {
  const { data } = props;
  const route = data ? data.location.pathname.split('/')[2] : '';
  const { data: dataFetched, openSnack } = useContext(RecrutementContext);
  console.log('props:', dataFetched);
  let unique = data && data.location.pathname.split('/')[3] ? true : false;
  const getActive = (params: string) => {
    switch (params) {
      case 'datascientist-et-machine-learning':
        return 1;
        break;
      case 'analyse-et-developpement-logiciel':
        return 2;
        break;
      case 'design-et-graphisme':
        return 3;
        break;
      case 'administration-et-autres':
        return 4;
        break;
      default:
        return 0;
    }
  };
  const getOffers = (params: string, unique: boolean) => {
    if (unique) {
      return dataFetched.filter((item) => item.slug === params);
    } else {
      switch (params) {
        case 'datascientist-et-machine-learning':
          return dataFetched.filter((item) => item.slugCategories === params);
          break;
        case 'analyse-et-developpement-logiciel':
          return dataFetched.filter((item) => item.slugCategories === params);
          break;
        case 'design-et-graphisme':
          return dataFetched.filter((item) => item.slugCategories === params);
          break;
        case 'administration-et-autres':
          return dataFetched.filter((item) => item.slugCategories === params);
          break;
        default:
          return dataFetched;
      }
    }
  };

  return (
    <Container>
      <SEO
        title="Hairun Technology - Recrutement"
        description="Rejoignez-nous"
      />
      <Recrutement
        offres={getOffers(
          unique ? data.location.pathname.split('/')[3] : route,
          unique,
        )}
        // index={index}
        open={unique}
        all={dataFetched}
        openSnack={openSnack}
        active={getActive(route)}
      />
    </Container>
  );
};

export default RecrutementPage;
