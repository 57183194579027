import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Dialog,
  Button,
  IconButton,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import styles from './style';
import IconClose from '@material-ui/icons/Close';
import SnackBar from '@material-ui/core/Snackbar';
import FileUpload from '../../../Common/FileUpload/FileUpload';
import { checkPhoneNumber } from '../../../../Services/validation';
import isEmail from 'validator/es/lib/isEmail';
import { sendMailContact, sendMailJoinUs } from '../../../../api/sendMail';

function Form({ open, handleClose }: any) {
  const classes = styles();

  const [snackBar, setSnackBar] = useState(false);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [tel, setTel] = useState<string>('');
  const [message, setMessage] = useState('');
  const [messageSnackBar, setMessageSnackBar] = useState('');
  const [subject, setsubject] = useState('Postuler');
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    switch (e.target.name) {
      case 'mail':
        setMail(e.target.value);
        break;
      case 'tel':
        setTel(e.target.value);
        break;
      case 'email':
        setMail(e.target.value);
        break;
      case 'message':
        setMessage(e.target.value);
        break;
      case 'nom':
        setName(e.target.value);
        break;
      default:
        break;
    }
  };
  const initState = () => {
    setMail('');
    setTel('');
    setName('');

    window.setTimeout(() => {
      if (handleClose) {
        handleClose();
        setSnackBar(false);
      }
    }, 2000);
  };
  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('send mail');
    if (!isEmail(mail)) {
      setMessageSnackBar("L'email n'est pas valide.");
      setSnackBar(true);
      return;
    }
    if (!checkPhoneNumber(tel)) {
      setMessageSnackBar("Le numéro de téléphone n'est pas valide");
      setSnackBar(true);
      return;
    }
    sendMailJoinUs(
      { name, tel, mail, subject, message },
      setSnackBar,
      setMessageSnackBar,
      initState,
    );
  };
  const handleCloseSnack = () => {
    setSnackBar(!snackBar);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.modal } }}
      onClose={handleClose}
    >
      <AppBar position="relative" color="secondary" elevation={0}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h4" align="center">
            Postuler à l'offre
          </Typography>
          <IconButton
            onClick={handleClose}
            className={classes.buttonClose}
            edge="end"
          >
            <IconClose />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.modalContent}>
        <Box>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              value={name}
              onChange={handleChange}
              label="Nom"
              name="nom"
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              type="email"
              name="email"
              label="Email"
              fullWidth
              value={mail}
              onChange={handleChange}
              required
            />
            <TextField
              variant="outlined"
              name="tel"
              label="Téléphone"
              value={tel}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              variant="outlined"
              label="Message"
              name="Message"
              multiline
              rows={5}
              fullWidth
              required
            />
            <FileUpload
              className={classes.uploadFile}
              maxFiles={2}
              label="Joindre votre CV et lettre de motivation"
            />

            <Button
              color="default"
              variant="contained"
              type="submit"
              disableElevation
              className={classes.sendButton}
              fullWidth
            >
              Envoyer
            </Button>
          </form>
        </Box>
      </Box>
      <SnackBar
        open={snackBar}
        message={messageSnackBar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleCloseSnack}
        action={
          <IconButton onClick={handleCloseSnack} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Dialog>
  );
}

export default Form;
