import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        id="Tracé_3600"
        data-name="Tracé 3600"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Tracé_3601"
        data-name="Tracé 3601"
        d="M10,12a2,2,0,1,0,2,2A2.006,2.006,0,0,0,10,12ZM6,8a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,8Zm0,8a2,2,0,1,0,2,2A2.006,2.006,0,0,0,6,16ZM18,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,18,8Zm-4,8a2,2,0,1,0,2,2A2.006,2.006,0,0,0,14,16Zm4-4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,18,12ZM14,8a2,2,0,1,0,2,2A2.006,2.006,0,0,0,14,8ZM10,4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,10,4Z"
      />
    </SvgIcon>
  );
}
