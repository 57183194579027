import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  scrollTop: {
    width: 'auto',
    maxWidth: 'fit-content',
    position: 'fixed',
    bottom: '15%',
    right: 50,
    zIndex: 5,
    '@media (max-width: 1919px)': {
      right: 25,
      '@media (max-height: 767px)': {
        bottom: '5%',
      },
    },
    '@media (max-width: 1799px)': {
      right: 15,
    },
    '& a': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      '& span': {
        writingMode: 'vertical-lr',
        display: 'inline-block',
        transform: 'rotate(-180deg)',
        '@media (max-width: 1919px)': {
          '@media (max-height: 767px)': {
            display: 'none',
          }
        },
      },
    },
    '& span': {
      color: '#ccc',
    },
  },
  imgWrapper: {
    width: 59,
    height: 59,
    border: '1px solid #ccc',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {},
  },
  divider: {
    display: 'inline-block',
    width: 1,
    height: 65,
    content: '""',
    backgroundColor: '#ccc',
    marginTop: 10,
    marginBottom: 10,
    '@media (max-width: 1919px)': {
      '@media (max-height: 767px)': {
        display: 'none',
      }
    },
  },
}));
