import { QueryClient } from 'react-query';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
export const storeData = (motCle: string, data: any) => {
  queryClient.setQueryData(motCle, data);
};
export const readData = (motCle: string) => {
  const data = queryClient.getQueryData(motCle);
  return data;
};
