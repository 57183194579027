import {
  Box,
  CardHeader,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import SnackBar from '@material-ui/core/Snackbar';
import IconClose from '@material-ui/icons/Close';
import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import ImgRejoindre from '../../../images/rejoindre/img_rejoignez.svg';
import { RecrutementContext } from '../../../providers/recrutement';
import ContactForm from '../../Common/ContactForm';
import AdministrationAutre from '../../Common/Icons/AdministrationAutre';
import AnalyseDeveveloppement from '../../Common/Icons/AnalyseDeveveloppement';
import DataScientist from '../../Common/Icons/DataScientist';
import DesignGraphisme from '../../Common/Icons/DesignGraphisme';
import ToutesCategories from '../../Common/Icons/ToutesCategories';
import Layout from '../../Common/Layout';
import ScrollTo from '../../Common/ScrollTo';
import { dataOffres } from './dataFetched';
import Offre from './Offre';
import styles from './style';
import { categorie_article, filterOffer } from './utils';

export interface Offre {
  category_offre: number;
  content: string;
  id: number;
  modified: string;
  title: string;
  categories: { nodes: { name: string } };
  typeContrat: string;
}
interface IRecrutement {
  offres: Offre[];
  active: number;
  open: boolean;
  all: Offre[];
  openSnack: boolean;
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function ListOffer(props: any) {
  const { open, offers } = props;

  return offers.length
    ? offers.map((node: any, index: number) => {
        const categorie = node.slugCategories;
        const slug = node.slug;

        return (
          <Offre
            open={open}
            slug={categorie}
            id={slug}
            index={index}
            title={node.title}
            content={node.content}
            date={node.modified}
            icon={categorie}
            key={index}
            type_contrat={node.typeContrat ? node.typeContrat : ''}
          />
        );
      })
    : 'Aucune offre disponible';
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  title: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, title, ...other } = props;

  const classes = styles();
  const screenMobile = useMediaQuery('(max-width:900px)');
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box>
          {screenMobile && <Typography variant="h3">{title}</Typography>}
          {children}
        </Box>
      )}
    </Box>
  );
}

const Recrutement = (props: IRecrutement) => {
  const { active, offres, all, open } = props;
  const classes = styles();
  const [value, setValue] = useState(active);
  const { openSnack, setOpenSnack } = useContext(RecrutementContext);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const screenMedium = useMediaQuery('(min-width:901px)');

  // const [openSnack, setOpenSnack] = useState(true);
  // console.log('data=>', dataOffres(setOpenSnack));

  const offer: any[] = all; //dataOffres(setOpenSnack);

  const offers = offres; //

  const datascience = filterOffer(offer, categorie_article.DATASCIENCE);
  const analyse = filterOffer(offer, categorie_article.ANALYSE);
  const design = filterOffer(offer, categorie_article.DESIGN);
  const admin = filterOffer(offer, categorie_article.ADMIN);
  const total =
    datascience.length + analyse.length + design.length + admin.length;
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box id="recrutementId" className={classes.recrutement}>
      {showScrollTo ? <ScrollTo element="/recrutement/#7025" /> : null}
      <Box>
        <Box className={classes.contentHeaderRecrutement}>
          <Box className={classes.BgHeader} />
          <Box
            className={classes.subContentHeaderRecrutement}
            maxWidth="1664px"
            margin="0 auto"
          >
            <Box component="div">
              <CardHeader
                title="Rejoignez-nous"
                className={classnames(classes.cardTitle, classes.cardTitleTop)}
                subheader="Libérez votre talent chez nous !"
              />
            </Box>
            <img className={classes.cardMedia1} src={ImgRejoindre} />
          </Box>
        </Box>
        <Layout className={classes.containerRecrutement}>
          <Box className={classes.tabsContainer}>
            <Tabs
              orientation={
                useMediaQuery('(max-width:650px)') ? 'horizontal' : 'vertical'
              }
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              className={classes.tabs}
            >
              <Tab
                icon={<ToutesCategories />}
                label={
                  screenMedium ? (
                    <Link
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      className={classes.link}
                      to={`/recrutement/`}
                    >
                      <Typography>{'Toutes les catégories'} </Typography>
                      <Typography style={{ marginLeft: 20, fontWeight: 800 }}>
                        {`(${total})`}{' '}
                      </Typography>
                    </Link>
                  ) : (
                    ''
                  )
                }
                {...a11yProps(0)}
                title="Toutes les catégories"
              />

              <Tab
                icon={<DataScientist />}
                label={
                  screenMedium ? (
                    <Link
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      className={classes.link}
                      to={`/recrutement/${categorie_article.DATASCIENCE}`}
                    >
                      {' '}
                      <Typography>
                        {'Datascientist & Machine Learning'}{' '}
                      </Typography>
                      <Typography style={{ marginLeft: 20, fontWeight: 800 }}>
                        {`(${datascience.length})`}
                      </Typography>
                    </Link>
                  ) : (
                    ''
                  )
                }
                {...a11yProps(1)}
              />

              <Tab
                icon={<AnalyseDeveveloppement />}
                label={
                  screenMedium ? (
                    <Link
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      className={classes.link}
                      to={`/recrutement/${categorie_article.ANALYSE}`}
                    >
                      {' '}
                      <Typography>
                        {'Analyse & Développement logiciel'}{' '}
                      </Typography>
                      <Typography style={{ marginLeft: 20, fontWeight: 800 }}>
                        {`(${analyse.length})`}
                      </Typography>
                    </Link>
                  ) : (
                    ''
                  )
                }
                {...a11yProps(2)}
              />

              <Tab
                icon={<DesignGraphisme />}
                // onClick={()=>}
                label={
                  screenMedium ? (
                    <Link
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      className={classes.link}
                      to={`/recrutement/${categorie_article.DESIGN}`}
                    >
                      {' '}
                      <Typography>{'Design & Graphismes'} </Typography>
                      <Typography style={{ marginLeft: 20, fontWeight: 800 }}>
                        {`(${design.length})`}
                      </Typography>
                    </Link>
                  ) : (
                    ''
                  )
                }
                {...a11yProps(3)}
              />
              <Tab
                icon={<AdministrationAutre />}
                label={
                  screenMedium ? (
                    <Link
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      className={classes.link}
                      to={`/recrutement/${categorie_article.ADMIN}`}
                    >
                      {' '}
                      <Typography>{'Administration & Autres'} </Typography>
                      <Typography style={{ marginLeft: 20, fontWeight: 800 }}>
                        {`(${admin.length})`}
                      </Typography>
                    </Link>
                  ) : (
                    ''
                  )
                }
                {...a11yProps(4)}
              />
            </Tabs>

            <TabPanel value={value} index={0} title="Toutes les catégories">
              <ListOffer open={open} offers={offer} />
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              title="Datascientist & Machine Learning"
            >
              <ListOffer open={open} offers={offers} />
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              title="Analyse & Développement logiciel"
            >
              <ListOffer open={open} offers={offers} />
            </TabPanel>
            <TabPanel value={value} index={3} title="Design & Graphisme">
              <ListOffer open={open} offers={offers} />
            </TabPanel>
            <TabPanel value={value} index={4} title="Administration & Autres">
              <ListOffer open={open} offers={offers} />
            </TabPanel>
          </Box>
        </Layout>
      </Box>
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
      <SnackBar
        open={openSnack}
        message={'chargement ...'}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleCloseSnack}
        action={
          <IconButton onClick={handleCloseSnack} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Box>
  );
};

export default Recrutement;
