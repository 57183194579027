import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19">
      <path
        id="Tracé_3607"
        data-name="Tracé 3607"
        d="M20,6H17V4a1.993,1.993,0,0,0-2-2H9A1.993,1.993,0,0,0,7,4V6H4A1.993,1.993,0,0,0,2,8V19a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V8A1.993,1.993,0,0,0,20,6ZM9,4h6V6H9ZM20,19H4V17H20Zm0-5H4V8H7v2H9V8h6v2h2V8h3Z"
        transform="translate(-2 -2)"
      />
    </SvgIcon>
  );
}
