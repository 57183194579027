import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path
        id="Tracé_3604"
        data-name="Tracé 3604"
        d="M11,9h2v2H11ZM9,11h2v2H9Zm4,0h2v2H13Zm2-2h2v2H15ZM7,9H9v2H7ZM19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM9,18H7V16H9Zm4,0H11V16h2Zm4,0H15V16h2Zm2-7H17v2h2v2H17V13H15v2H13V13H11v2H9V13H7v2H5V13H7V11H5V5H19Z"
        transform="translate(-3 -3)"
      />
    </SvgIcon>
  );
}
