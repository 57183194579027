import moment from 'moment';
import React, { createContext, FC, useEffect, useState } from 'react';
import { useQuery, MutationCache } from 'react-query';
import { readData, storeData } from './logic';

interface Offre {
  category_offre: number;
  content: string;
  id: number;
  modified: string;
  title: string;
  categories: { nodes: { name: string } };
  typeContrat: string;
  slug: string;
  slugCategories: string;
}
interface IRecrutement {
  openSnack: boolean;
  setOpenSnack: (openSnack: boolean) => void;
  data: Offre[];
  setData: (data: Offre[]) => void;
}
export const RecrutementContext = createContext<IRecrutement>({
  data: [],
  setData: (data: Offre[]) => {},
  openSnack: true,
  setOpenSnack: (openSnack: boolean) => {},
});
export const RecrutementProvider: FC = ({ children }) => {
  const [data, setData] = useState<Offre[]>([]);
  const [openSnack, setOpenSnack] = useState(true);
  let offresTemp: Offre[] = [];
  console.log('contetx');
  const loadPosts = async () => {
    const dataFromCache = await readData('offres');
    if (dataFromCache) {
      setData(dataFromCache as any);
      setOpenSnack && setOpenSnack(false);
      return;
    }
    const responseCategory = await fetch(
      'https://blog.hairun-technology.com/wp-json/wp/v2/category_offre',
    );
    if (!responseCategory.ok) {
      // oups! something went wrong
      setOpenSnack && setOpenSnack(false);
      console.log('error fetch', responseCategory);

      return;
    }
    const postCat = await responseCategory.json();

    const response = await fetch(
      'https://blog.hairun-technology.com/wp-json/wp/v2/offre?per_page=100',
    );
    if (!response.ok) {
      // oups! something went wrong
      console.log('error fetch');
      setOpenSnack && setOpenSnack(false);
      return;
    }

    const post = await response.json();

    const responseType = await fetch(
      'https://blog.hairun-technology.com/wp-json/wp/v2/type_contrat',
    );
    if (!response.ok) {
      // oups! something went wrong
      console.log('error fetch');
      setOpenSnack && setOpenSnack(false);
      return;
    }

    const typePost = await responseType.json();

    if (post) {
      for (const itemPosts of post) {
        // s
        let categorie: any;
        let type_contrat: any;

        if (typePost && itemPosts.type_contrat[0]) {
          type_contrat = typePost.filter((item: any) => {
            return item.id === itemPosts.type_contrat[0];
          });
        }

        if (postCat) {
          categorie = postCat.filter((item: any) => {
            return item.id === itemPosts.category_offre[0];
          });
        }
        console.log('cat:', postCat);

        offresTemp.push({
          category_offre: itemPosts.category_offre[0],
          content: itemPosts.content.rendered,
          id: itemPosts.id,
          title: itemPosts.title.rendered,
          categories: { nodes: { name: categorie[0].name } },
          modified: moment(itemPosts.date).locale('fr').format('DD MMMM YYYY'),
          typeContrat: type_contrat ? type_contrat[0].name : '',
          slugCategories: categorie[0].slug,
          slug: itemPosts.slug,
        });
      }
    }

    setData(offresTemp);
    await storeData('offres', offresTemp);
    setOpenSnack && setOpenSnack(false);
    //  setPosts(posts);
  };

  useEffect(() => {
    console.log('post');
    loadPosts();
  }, []);

  return (
    <RecrutementContext.Provider
      value={{ data, setData, openSnack, setOpenSnack }}
    >
      {children}
    </RecrutementContext.Provider>
  );
};
