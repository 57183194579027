import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        id="Tracé_3598"
        data-name="Tracé 3598"
        d="M3,3V21H21V3Zm8,16H5V13h6Zm0-8H5V5h6Zm8,8H13V13h6Zm0-8H13V5h6Z"
      />
      <path
        id="Tracé_3599"
        data-name="Tracé 3599"
        d="M0,0H24V24H0Z"
        fill="none"
      />
    </SvgIcon>
  );
}
