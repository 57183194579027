export enum categorie_article {
  DATASCIENCE = 'datascientist-et-machine-learning',
  ANALYSE = 'analyse-et-developpement-logiciel',
  DESIGN = 'design-et-graphisme',
  ADMIN = 'administration-et-autres',
  DATASCIENCE_slug = 'datascientist-et-machine-learning',
}

export const filterOffer = (list: any, filtre: string) => {
  let newOffers = [];
  if (filtre === 'all') {
    return list;
  }

  if (list.length) {
    newOffers = list.filter((item: any) => {
      const categorie = item.slugCategories;

      return categorie === filtre;
    });
  } else {
    return list;
  }
  return newOffers;
};
